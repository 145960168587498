<template>
  <div data-app>
    <v-card>
      <v-card-title v-if="routeName == 'media_library'">
        <h3 style="color: #e33354" class="ml-2">
          {{ $t("MENU.MEDIA_LIBRARY") }}
          <TooltipModal
            :title="$t('ALERT.info_title')"
            :text="$helpers.getActiveSiteI18Property('INFO_BOX.MEDIA_LIBRARY')"
          ></TooltipModal>
        </h3>
      </v-card-title>
      <v-card-title v-if="routeName == 'internal_documents'">
        <h3 style="color: #e33354" class="ml-2">
          {{ $t("MENU.INTERNAL_DOCUMENTS") }}
        </h3>
      </v-card-title>
      <ol class="breadcrumb text-muted fs-6 fw-bold">
        <li
          v-for="(item, index) in breadcrumb"
          :key="index"
          class="breadcrumb-item"
        >
          <a href="#" @click="enterDirectory(item.path)">{{ item.name }}</a>
        </li>
      </ol>
      <v-container fluid>
        <v-row dense>
          <DirectoryModalForm
            :directoryModalData="directoryModalData"
            :actualDirectory="actual_directory"
            @closeDirectoryModalForm="handleCloseDirectoryModalForm"
            @saveDirectoryModalForm="handleSaveDirectoryModalForm"
          ></DirectoryModalForm>

          <FileUploadModalForm
            :fileUploadModalData="fileUploadModalData"
            :actualDirectory="actual_directory"
            @closeFileUploadModalForm="handleCloseFileUploadModalForm"
          ></FileUploadModalForm>

          <FileModalForm
            :fileModalData="fileModalData"
            :actualDirectory="actual_directory"
            @closeFileModalForm="handleCloseFileModalForm"
            @saveFileModalForm="handleSaveFileModalForm"
          ></FileModalForm>

          <DeleteModalDialog
            :dialogDelete="dialogDelete"
            :dialogDeleteTitle="dialogDeleteTitle"
            @closeDelete="handleCloseDelete"
            @deleteItemConfirm="handleDeleteItemConfirm"
          >
          </DeleteModalDialog>

          <CropImageModalForm
            :cropImageModalData="cropImageModalData"
            :actualDirectory="actual_directory"
            @closeCropImageModalForm="handleCloseCropImageModalForm"
            @cropAndSaveImageModalForm="handleCropAndSaveImageModalForm"
          ></CropImageModalForm>

          <vue-easy-lightbox
            :visible="vue_easy_lightbox_visible"
            :imgs="vue_easy_lightbox_imgs"
            :index="vue_easy_lightbox_index"
            @hide="handleVueEasyLightboxHide"
            @on-index-change="handleIndexChange"
          ></vue-easy-lightbox>
          <v-text-field
            v-model.trim="searchText"
            dense
            filled
            rounded
            clearable
            @click:clear="handleClearSearchText"
            :placeholder="$t('FORMS.search')"
            prepend-inner-icon="mdi-magnify"
            class="mr-4 shrink expanding-search"
            :class="{ closed: searchBoxClosed && !searchText }"
            @keyup.enter="onSearch"
            @focus="searchBoxClosed = false"
            @blur="searchBoxClosed = true"
          ></v-text-field>
          <v-checkbox
            v-if="config"
            class="mr-4 mt-1"
            v-model="selectAll"
            :label="$t('FORM_INPUT_NAMES.select_all')"
            hide-details
            :disabled="config.maxFiles == 1"
          >
          </v-checkbox>
          <v-btn-toggle v-model="sortedByIndex" dense mandatory>
            <v-btn @click.stop="handleChangeOrder(0)">
              <v-icon v-if="sortBy[0].orderByAsc"> mdi-sort-ascending </v-icon>
              <v-icon v-else> mdi-sort-descending </v-icon>
              {{ $t("ALERT.created_at") }}
            </v-btn>

            <v-btn @click.stop="handleChangeOrder(1)">
              <v-icon v-if="sortBy[1].orderByAsc"> mdi-sort-ascending </v-icon>
              <v-icon v-else> mdi-sort-descending </v-icon>
              {{ $t("ALERT.name") }}
            </v-btn>

            <v-btn @click.stop="handleChangeOrder(2)">
              <v-icon v-if="sortBy[2].orderByAsc"> mdi-sort-ascending </v-icon>
              <v-icon v-else> mdi-sort-descending </v-icon>
              {{ $t("ALERT.size") }}
            </v-btn>
          </v-btn-toggle>
          <v-btn-toggle dense mandatory class="ml-2">
            <v-btn @click.stop="handleChangeViewType">
              <v-icon v-if="viewType == 'list'"> mdi-view-grid </v-icon>
              <v-icon v-else> mdi-view-list </v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-row>
        <v-row v-if="viewType == 'grid'">
          <v-col v-if="!isRootFolder()" :class="column_class">
            <v-card elevation="10" align="center" width="100%" height="100%">
              <v-img
                src="/media/media_library/arrow-up-left-bold.svg"
                height="125"
                @click.stop="exitDirectory()"
                style="cursor: pointer"
                contain
              ></v-img>
              <v-card-subtitle style="font-weight: bold; font-size: 22px"
                >. .</v-card-subtitle
              >
            </v-card>
          </v-col>

          <v-col
            v-for="(directory, index) in directories"
            :key="directory.path"
            :class="column_class"
          >
            <v-card elevation="10" align="center" width="100%" height="100%">
              <v-img
                :src="directory.icon"
                @click="enterDirectory(directory.path)"
                height="125"
                style="cursor: pointer"
                :title="directory.name"
                contain
              ></v-img>
              <v-card-subtitle>
                {{ getShortName(directory.name) }}
                <span v-if="num_of_subdirectory_items.length > 0">
                  ({{ getNumOfSubdirectoryItems(index) }})
                </span>
              </v-card-subtitle>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-icon
                  small
                  color="primary"
                  @click="deleteItem({ type: 'DIR', path: directory.path })"
                >
                  mdi-delete
                </v-icon>
                <v-icon
                  v-if="!opener"
                  small
                  color="success"
                  @click.stop="handleShare(directory.path, null)"
                >
                  mdi-share
                </v-icon>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col
            v-for="(file, index) in files"
            :key="file.path"
            :class="column_class"
          >
            <v-card elevation="10" align="center" width="100%" height="100%">
              <v-img
                v-if="previewable_mime_types.includes(file.mime_type)"
                :src="getFileThumbnail(file)"
                height="175"
                style="cursor: pointer"
                :title="file.name"
                @click="() => showImg(index)"
              ></v-img>
              <a v-else :href="getFile(file)" target="_blank">
                <v-img
                  :src="getIcon(file)"
                  :title="file.name"
                  height="175"
                  contain
                />
              </a>
              <v-card-actions style="height: 40px">
                <v-icon
                  class="mr-1"
                  small
                  v-if="routeName == 'media_library' && opener"
                  @click.stop="handleSelectOneImage(file.path)"
                  >mdi-checkbox-blank-outline</v-icon
                >

                <v-checkbox
                  class="mt-3 mr-1"
                  dense
                  v-if="$route.path != '/cms/media_library'"
                  v-model="selectedFiles"
                  :value="file.path"
                  @change="handleSelectOneImage(file.path)"
                >
                </v-checkbox>
                {{ file.name.substring(0, 23) }}
                <v-spacer></v-spacer>
                <v-icon
                  small
                  class="mr-1"
                  v-if="
                    previewable_mime_types.includes(file.mime_type) &&
                    config &&
                    config.resolution
                  "
                  @click="handleEditCropImageModalForm(file.path)"
                  color="primary"
                  >mdi-crop</v-icon
                >
                <v-icon
                  small
                  class="mr-1"
                  color="primary"
                  @click.stop="handleInfoBox(file)"
                >
                  mdi-information-outline
                </v-icon>

                <v-icon
                  small
                  color="primary"
                  @click="deleteItem({ type: 'FILE', path: file.path })"
                >
                  mdi-delete
                </v-icon>
                <v-icon
                  v-if="!opener"
                  small
                  color="success"
                  @click.stop="handleShare(actual_directory, file.path)"
                >
                  mdi-share
                </v-icon>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th></th>
                    <th class="text-left"></th>
                    <th class="text-left">{{ $t("TABLE.name") }}</th>
                    <th class="text-left">{{ $t("TABLE.created_at") }}</th>
                    <th class="text-left">{{ $t("TABLE.size") }}</th>
                    <th class="text-left">{{ $t("TABLE.actions") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!isRootFolder()" @click.stop="exitDirectory()">
                    <td></td>
                    <td class="py-1">
                      <v-card
                        elevation="10"
                        align="center"
                        width="75"
                        height="100%"
                      >
                        <v-img
                          src="/media/media_library/arrow-up-left-bold.svg"
                          height="65"
                          style="cursor: pointer"
                          contain
                        ></v-img>
                      </v-card>
                    </td>
                    <td>..</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>

                  <tr
                    v-for="(directory, index) in directories"
                    :key="directory.path"
                    @click="enterDirectory(directory.path)"
                  >
                    <td></td>
                    <td class="py-1">
                      <v-card
                        elevation="10"
                        align="center"
                        width="75"
                        height="100%"
                      >
                        <v-img
                          :src="directory.icon"
                          height="65"
                          style="cursor: pointer"
                          :title="directory.name"
                          contain
                        ></v-img>
                      </v-card>
                    </td>
                    <td>
                      {{ directory.name }}
                      <span v-if="num_of_subdirectory_items.length > 0">
                        ({{ getNumOfSubdirectoryItems(index) }})
                      </span>
                    </td>
                    <td></td>
                    <td></td>
                    <td>
                      <v-icon
                        small
                        color="primary"
                        @click.stop="
                          deleteItem({ type: 'DIR', path: directory.path })
                        "
                      >
                        mdi-delete
                      </v-icon>
                      <v-icon
                        v-if="!opener"
                        small
                        color="success"
                        @click.stop="handleShare(directory.path, null)"
                      >
                        mdi-share
                      </v-icon>
                    </td>
                  </tr>
                  <!-- ********************************
********************************
******************************** -->
                  <tr
                    v-for="(file, index) in files"
                    :key="file.path"
                    @click="() => showImg(index)"
                  >
                    <td width="20">
                      <v-icon
                        small
                        v-if="routeName == 'media_library' && opener"
                        @click.stop="handleSelectOneImage(file.path)"
                        >mdi-checkbox-blank-outline</v-icon
                      >
                      <v-checkbox
                        dense
                        v-if="
                          routeName != 'media_library' &&
                          routeName != 'internal_documents'
                        "
                        v-model="selectedFiles"
                        :value="file.path"
                        @click.stop
                        @change="handleSelectOneImage(file.path)"
                      >
                      </v-checkbox>
                    </td>
                    <td class="py-1">
                      <v-card
                        elevation="10"
                        align="center"
                        width="75"
                        height="100%"
                        class="py-1"
                      >
                        <!-- @click.stop="() => showImg(index)" -->
                        <v-img
                          v-if="previewable_mime_types.includes(file.mime_type)"
                          :src="getFileThumbnail(file)"
                          height="65"
                          width="65"
                          style="cursor: pointer"
                          :title="file.name"
                        ></v-img>
                        <a v-else :href="getFile(file)" target="_blank">
                          <v-img
                            :src="getIcon(file)"
                            :title="file.name"
                            height="65"
                            contain
                          />
                        </a>
                      </v-card>
                    </td>
                    <td>
                      {{ file.name }}
                    </td>
                    <td>
                      <!-- {{ file.created_at.substring(0, 18) }} -->
                      {{ getFormattedDateTime(file.created_at) }}
                    </td>
                    <td>{{ bytesToSize(file.size) }}</td>
                    <td>
                      <v-icon
                        small
                        class="mr-1"
                        v-if="
                          previewable_mime_types.includes(file.mime_type) &&
                          config &&
                          config.resolution
                        "
                        @click.stop="handleEditCropImageModalForm(file.path)"
                        color="primary"
                        >mdi-crop</v-icon
                      >
                      <v-icon
                        small
                        class="mr-1"
                        color="primary"
                        @click.stop="handleInfoBox(file)"
                      >
                        mdi-information-outline
                      </v-icon>
                      <v-icon
                        small
                        color="primary"
                        @click.stop="
                          deleteItem({ type: 'FILE', path: file.path })
                        "
                      >
                        mdi-delete
                      </v-icon>
                      <v-icon
                        v-if="!opener"
                        small
                        color="success"
                        @click.stop="handleShare(actual_directory, file.path)"
                      >
                        mdi-share
                      </v-icon>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <SnackBarInfoComponent :snackbarInfo="snackbarInfo">
        </SnackBarInfoComponent>
      </v-container>
      <!-- routeName: {{ routeName }}
      <br />
      root_directory: {{ root_directory }}
      <br />
      actual_directory: {{ actual_directory }}
      <br />
      getSiteSpecificRootFolder: {{ getSiteSpecificRootFolder() }} -->
      <!-- <pre>
      {{ files }}
      </pre>
      <pre>
      MIME_TYPE_ICONS: {{ MIME_TYPE_ICONS }}
      </pre> -->
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import VueEasyLightbox from "vue-easy-lightbox";
import ApiService from "@/core/services/api.service";
import SiteService from "@/core/services/site.service.js";
import DirectoryModalForm from "./DirectoryModalForm";
import FileUploadModalForm from "./FileUploadModalForm";
import FileModalForm from "./FileModalForm";
import DeleteModalDialog from "@/view/components/DeleteModalDialog";
import CropImageModalForm from "./CropImageModalForm";
export const DIRECTORY_ENDPOINT = "attachment/directory";
export const FILE_ENDPOINT = "attachment/file";
// export const ROOT_DIRECTORY = "public/media";
export const ROOT_DIRECTORY = "public";
// export const ROOT_DIRECTORY = "actual_directory";
import Swal from "sweetalert2";
import { mediaLibraryMixins } from "@/view/mixins/mediaLibraryMixins.js";
import moment from "moment";
import SnackBarInfoComponent from "@/view/components/SnackBarInfoComponent";
import axios from "axios";

export const MIME_TYPE_ICONS = {
  "application/pdf": "pdf.svg",
  "application/msword": "file-word.svg",
  "text/html": "HTML-Icon-Final.svg",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "file-word.svg",
  "application/gzip": "zip-box-outline.svg",
  "audio/mpeg": "book-music.svg",
  "video/mp4": "video-outline.svg",
  "video/mpeg": "video-outline.svg",
  "application/vnd.rar": "zip-box-outline.svg",
  "application/rtf": "text-box-multiple-outline.svg",
  //'image/svg+xml': '',
  "audio/webm": "book-music.svg",
  "video/webm": "video-outline.svg",
  "application/vnd.ms-excel": "file-excel-outline.svg",

  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    "file-excel-outline.svg",
  "application/zip": "zip-box-outline.svg",
  "application/x-7z-compressed": "zip-box-outline.svg",

  // "image/svg+xml": "image.svg",
  "image/png": "image.svg",
  "image/jpeg": "image.svg",
  "image/x-ms-bmp": "image.svg",
  "image/webp": "image.svg",
  "image/gif": "image.svg",
  "image/svg+xml": "SVG.svg",
};

const INITIAL_SORT_BY = [
  {
    orderByAsc: false,
    property: "created_at",
  },
  {
    orderByAsc: true,
    property: "name",
  },
  {
    orderByAsc: true,
    property: "size",
  },
];

export default {
  mixins: [mediaLibraryMixins],
  name: "Browser",
  props: ["config"],
  components: {
    DirectoryModalForm,
    FileUploadModalForm,
    FileModalForm,
    DeleteModalDialog,
    CropImageModalForm,
    VueEasyLightbox,
    SnackBarInfoComponent,
  },
  data() {
    return {
      routeName: "",
      opener: window.opener,
      loader: false,
      directories: null,
      files: null,
      num_of_subdirectory_items: [],
      actual_directory: ROOT_DIRECTORY,
      root_directory: ROOT_DIRECTORY,
      breadcrumb: [],
      site_specific_root_directory: SiteService.getActiveSiteId(),
      directoryModalData: {
        dialog: false,
        old_name: null,
      },
      fileUploadModalData: {
        dialog: false,
      },
      fileModalData: {
        dialog: false,
        old_name: null,
      },
      cropImageModalData: {
        dialog: false,
        path: null,
        resolution: "0x0",
      },
      dialogDelete: false,
      editedItem: Object.assign(
        {},
        {
          type: null,
          path: null,
        }
      ),
      // previewable_mime_types: [
      //   "image/png",
      //   "image/jpeg",
      //   "image/x-ms-bmp",
      //   "image/webp",
      //   "image/gif",
      //   "image/svg+xml",
      // ],
      // column_class: "col-4 col-md-3 col-lg-2 col-xl-1",
      column_class: "col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12",

      vue_easy_lightbox_visible: false,
      vue_easy_lightbox_index: 0,
      // vue_easy_lightbox_imgs: [],
      selectedFiles: [],
      selectAll: false,
      sortBy: Object.assign({}, INITIAL_SORT_BY),
      sortedByIndex: undefined,
      maxWidth: null,
      funcNum: null,
      searchText: null,
      searchBoxClosed: true,
      viewType: "grid",
      snackbarInfo: {
        timeout: 1000,
        active: false,
        text: "A link a vágólapra másolva!",
        icon: "check_circle",
        color: "green",
      },
    };
  },

  computed: {
    previewable_mime_types() {
      if (this.routeName == "internal_documents") {
        return [];
      }
      return [
        "image/png",
        "image/jpeg",
        "image/x-ms-bmp",
        "image/webp",
        "image/gif",
        // "image/svg+xml",
      ];
    },

    canSelectFile() {
      if (
        !this.config ||
        !this.config.maxFiles ||
        this.selectedFiles.length <= this.config.maxFiles ||
        this.config.maxFiles == -1
      ) {
        return true;
      }
      return false;
    },

    vue_easy_lightbox_imgs() {
      let imgs = [];
      if (!this.files) return imgs;
      this.files.forEach((file) => {
        if (this.previewable_mime_types.includes(file.mime_type)) {
          imgs.push({
            src: this.getFile(file),
            title: file.name,
          });
        }
      });
      return imgs;
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.handleCloseDelete();
    },

    selectAll(val) {
      let selectedFiles = [];
      if (!val) {
        this.selectedFiles = [];
      } else {
        this.selectedFiles = this.files.map((item) => item.path);

        if (this.config.maxFiles != -1) {
          this.selectedFiles = this.selectedFiles.slice(
            0,
            this.config.maxFiles
          );
        }

        selectedFiles = this.selectedFiles.map((item) => {
          let file = this.files.find((file) => file.path == item);
          if (file) {
            return file;
          }
        });
      }
      this.$emit("handleSelectMedia", selectedFiles);
    },
  },

  methods: {
    setBreadcrumb() {
      this.breadcrumb = [];
      let actual_path_array = this.actual_directory.split("/");
      for (let i = 0; i < actual_path_array.length; i++) {
        let breadcrumb_item_path = [];
        for (let j = 0; j <= i; j++) {
          breadcrumb_item_path.push(actual_path_array[j]);
        }

        if (this.routeName == "internal_documents") {
          if (i == 0) {
            this.breadcrumb.push({
              name: this.$t("FORMS.shared_files"),
              path: breadcrumb_item_path.join("/"),
            });
          } else {
            this.breadcrumb.push({
              name: actual_path_array[i],
              path: breadcrumb_item_path.join("/"),
            });
          }
        } else {
          if (i == 0) {
            continue;
          } else if (i == 1) {
            this.breadcrumb.push({
              name: this.$t("FORMS.shared_files"),
              path: breadcrumb_item_path.join("/"),
            });
          } else {
            this.breadcrumb.push({
              name: actual_path_array[i],
              path: breadcrumb_item_path.join("/"),
            });
          }
        }
      }
    },
    enterDirectory(relative_path) {
      if (!this.dialogDelete) {
        this.listDirectory(relative_path);
      }
    },
    exitDirectory() {
      let actual_path_array = this.actual_directory.split("/");
      actual_path_array.pop();
      if (actual_path_array.length > 0) {
        let relative_path = actual_path_array.join("/");
        this.listDirectory(relative_path);
      }
    },
    listDirectory(relative_path = "") {
      this.loader = true;
      if (relative_path === "") {
        relative_path = this.getSiteSpecificRootFolder();
      }
      ApiService.get(DIRECTORY_ENDPOINT, relative_path)
        .then((response) => {
          let directories = [];
          for (let i in response.data.directories) {
            let directory = response.data.directories[i];
            let path_array = directory.split("/");
            let name = path_array[path_array.length - 1];
            directories.push({
              path: directory,
              name: name,
              icon: "/media/media_library/folder.svg",
            });
          }
          this.directories = directories;
          let files = [];
          // this.vue_easy_lightbox_imgs = [];
          for (let i in response.data.attachments) {
            let attachment = response.data.attachments[i];
            files.push({
              path: attachment.path,
              name: attachment.filename,
              mime_type: attachment.mime_type,
              size: attachment.size,
              created_at: attachment.created_at,
              user: attachment.uploader_id,
            });
            // if (this.previewable_mime_types.includes(attachment.mime_type)) {
            //   //console.log(this.getFile(attachment));
            //   this.vue_easy_lightbox_imgs.push({
            //     src: this.getFile(attachment),
            //     title: attachment.filename,
            //   });
            // }
          }
          this.files = files;
          this.actual_directory = relative_path;

          this.setPathParams(this.actual_directory, null);

          sessionStorage.setItem("actual_directory", this.actual_directory);

          this.setBreadcrumb();
          this.num_of_subdirectory_items =
            response.data.num_of_subdirectory_items;
          this.sortFiles();
          this.loader = false;
          this.searchText = null;
          this.searchBoxClosed = true;

          this.openFromURL(this.$route.query.filePath ?? null);
        })
        .catch((error) => {
          console.log("Error!: ", error);
          this.loader = false;
        });
    },
    handleCloseDirectoryModalForm() {
      this.directoryModalData.dialog = false;
      this.directoryModalData.old_name = null;
    },
    handleSaveDirectoryModalForm() {
      this.listDirectory(this.actual_directory);
      this.handleCloseDirectoryModalForm();
    },
    handleEditDirectoryModalForm(name) {
      this.directoryModalData.dialog = true;
      this.directoryModalData.old_name = name;
    },
    handleCloseFileUploadModalForm() {
      this.listDirectory(this.actual_directory);
      this.fileUploadModalData.dialog = false;
    },
    handleEditFileModalForm(name) {
      this.fileModalData.dialog = true;
      this.fileModalData.old_name = name;
    },
    handleSaveFileModalForm() {
      this.listDirectory(this.actual_directory);
      this.handleCloseFileModalForm();
    },
    handleCloseFileModalForm() {
      this.fileModalData.dialog = false;
      this.fileModalData.old_name = null;
    },
    deleteItem(item) {
      this.dialogDeleteTitle =
        "Valóban törölni szeretnéd a kiválasztott elemet?";
      this.editedItem = Object.assign({}, item);
      if (item.type == "DIR") {
        this.deleteDirectoryConfirm(item.path);
      } else {
        this.dialogDelete = true;
      }
    },
    deleteDirectoryConfirm(relative_path) {
      this.loader = true;
      ApiService.get(DIRECTORY_ENDPOINT, relative_path)
        .then((response) => {
          if (
            response.data.directories.length > 0 ||
            response.data.files.length
          ) {
            this.dialogDeleteTitle =
              "A könyvtár nem üres! Valóban törölni szeretnéd a kiválasztott elemet?";
          }
          this.dialogDelete = true;
          this.loader = false;
        })
        .catch((error) => {
          console.log("Error!: ", error);
          this.loader = false;
        });
    },
    handleDeleteItemConfirm() {
      this.loader = true;
      if (this.editedItem.type == "DIR") {
        ApiService.delete(DIRECTORY_ENDPOINT + "/" + this.editedItem.path)
          .then(() => {
            this.listDirectory(this.actual_directory);
            this.loader = false;
          })
          .catch((error) => {
            console.log("Error!: ", error);
            this.loader = false;
          });
        this.handleCloseDelete();
      } else if (this.editedItem.type == "FILE") {
        ApiService.delete(FILE_ENDPOINT + "/" + this.editedItem.path)
          .then(() => {
            this.listDirectory(this.actual_directory);
            this.loader = false;
          })
          .catch((error) => {
            console.log("Error!: ", error);
            this.loader = false;
          });
        this.handleCloseDelete();
      }
    },
    handleCloseDelete() {
      this.dialogDelete = false;
      this.editedItem = Object.assign(
        {},
        {
          type: null,
          path: null,
        }
      );
    },
    handleCloseCropImageModalForm() {
      this.cropImageModalData.dialog = false;
      this.cropImageModalData.path = null;
    },
    handleEditCropImageModalForm(path) {
      this.cropImageModalData = {
        dialog: true,
        path: path,
        resolution: this.config.resolution,
      };
    },
    handleCropAndSaveImageModalForm(croppedFile = null) {
      if (croppedFile) {
        this.$emit("handleSelectMedia", croppedFile, true);
      }
      this.listDirectory(this.actual_directory);
      this.handleCloseCropImageModalForm();
    },
    getIcon(file) {
      // console.log("getIcon(file)", file);
      // console.log(
      //   "MIME_TYPE_ICONS[file.mime_type]",
      //   MIME_TYPE_ICONS[file.mime_type]
      // );
      if (
        file.mime_type in MIME_TYPE_ICONS &&
        MIME_TYPE_ICONS[file.mime_type] != ""
      ) {
        return (
          "/media/media_library/mime_types/" + MIME_TYPE_ICONS[file.mime_type]
        );
      } else {
        // return "/media/media_library/file-outline.svg";
        return "/media/media_library/mime_types/Binary-file.svg";
      }
    },
    getFile(file) {
      if (this.routeName == "internal_documents") return null;
      return (
        process.env.VUE_APP_BACKEND_URL.replace(/\/+$/, "") + "/" + file.path
      );
    },

    getFileThumbnail(file) {
      return (
        process.env.VUE_APP_BACKEND_URL.replace(/\/+$/, "") +
        "/" +
        file.path.replace("public", "image/250x175")
      );
    },

    getShortName(name) {
      if (name.length > 15) {
        name = name.substring(0, 14) + "...";
      }
      return name;
    },
    //vue-easy-lightbox
    showImg(index) {
      // this.vue_easy_lightbox_index = index;
      // this.vue_easy_lightbox_visible = true;

      let file = this.files[index];
      if (this.routeName == "internal_documents") {
        this.handleInfoBox(file, true);
        return null;
      }

      this.setPathParams(this.actual_directory, index);

      this.vue_easy_lightbox_index = this.vue_easy_lightbox_imgs.findIndex(
        (item) => item.src == this.getFile(file)
      );
      if (this.vue_easy_lightbox_index == -1) {
        this.vue_easy_lightbox_index = null;
        return null;
      }
      this.vue_easy_lightbox_visible = true;
    },

    handleVueEasyLightboxHide() {
      this.vue_easy_lightbox_visible = false;
      this.setPathParams(this.actual_directory, null);
    },

    //CKEDITOR
    getUrlParam(paramName) {
      var reParam = new RegExp("(?:[?&]|&)" + paramName + "=([^&]+)", "i");
      var match = window.location.href.match(reParam);
      return match && match.length > 1 ? match[1] : null;
    },

    handleSelectOneImage(file_path) {
      // ha ckeditorbol van nyitva
      if (this.$route.path == "/cms/media_library") {
        let fileUrl = process.env.VUE_APP_BACKEND_URL + "/" + file_path;

        // let funcNum = this.getUrlParam("CKEditorFuncNum");

        // let maxWidth = this.getUrlParam("maxWidth");
        // console.log("funcNum", this.funcNum);
        // console.log("maxWidth", this.maxWidth);
        if (this.maxWidth) {
          let selectedFile = this.files.find((file) => file.path == file_path);
          if (selectedFile) {
            let img = new Image();
            img.src = this.getFile(selectedFile);
            img.onload = () => {
              if (this.maxWidth < img.width) {
                let ratio = img.width / img.height;
                let height = parseInt(this.maxWidth / ratio);
                fileUrl =
                  process.env.VUE_APP_BACKEND_URL +
                  "image/" +
                  this.maxWidth +
                  "x" +
                  height +
                  "/" +
                  file_path.replace("public/", "");
                // console.log(this.maxWidth, height);
                // console.log(fileUrl);
                window.opener.CKEDITOR.tools.callFunction(
                  this.funcNum,
                  fileUrl
                );
                window.close();
              } else {
                window.opener.CKEDITOR.tools.callFunction(
                  this.funcNum,
                  fileUrl
                );
                window.close();
                return null;
              }
            };
          }
        } else {
          window.opener.CKEDITOR.tools.callFunction(this.funcNum, fileUrl);
          window.close();
        }
      } else {
        if (this.canSelectFile) {
          let selectedFiles = this.selectedFiles.map((item) => {
            let file = this.files.find((file) => file.path == item);
            if (file) {
              return file;
            }
          });
          this.$emit("handleSelectMedia", selectedFiles);
        } else {
          this.selectedFiles.pop();
          Swal.fire({
            title: "",
            text: this.$t("ALERT.choose_only_maxFiles", {
              maxFiles: this.config.maxFiles,
            }),
            icon: "error",
          });
        }
      }
    },

    isRootFolder() {
      let foldername = this.root_directory;
      if (this.site_specific_root_directory > 0) {
        foldername =
          this.root_directory + "/" + this.site_specific_root_directory;
      }

      return this.actual_directory == foldername;
    },

    getSiteSpecificRootFolder() {
      var foldername = "";
      if (this.site_specific_root_directory <= 0) {
        foldername = this.root_directory;
      } else {
        foldername =
          this.root_directory + "/" + this.site_specific_root_directory;
      }

      let sessionActualDirectory = sessionStorage.getItem("actual_directory");
      if (
        sessionActualDirectory &&
        foldername.substring(0, 7) == sessionActualDirectory.substring(0, 7)
      ) {
        foldername = sessionActualDirectory;
      }
      return foldername;
    },

    handleInfoBox(file, download = false) {
      if (
        this.previewable_mime_types.includes(file.mime_type) &&
        this.routeName != "internal_documents"
      ) {
        let img = new Image();
        img.src = this.getFile(file);
        img.onload = () => {
          Swal.fire({
            title: `<strong>${this.$t("ALERT.file_info_title")}</strong>`,
            customClass: "swal-wide",
            width: "500px",
            html:
              `<b>${this.$t("ALERT.created_at")}:</b> ` +
              this.getFormattedDateTime(file.created_at) +
              "<br>" +
              `<b>${this.$t("ALERT.name")}:</b> ` +
              file.name +
              "<br>" +
              `<b>${this.$t("ALERT.mime_type")}:</b> ` +
              file.mime_type +
              "<br>" +
              `<b>${this.$t("ALERT.path")}:</b>
                      <a href="` +
              this.getFile(file) +
              `" target="_blank">` +
              this.getFile(file) +
              `</a>` +
              "<br>" +
              `<b>${this.$t("ALERT.size")}:</b> ` +
              this.bytesToSize(file.size) +
              "<br>" +
              `<b>${this.$t("ALERT.width")}:</b> ` +
              img.width +
              ` ${this.$t("ALERT.pixel")} ` +
              "<br>" +
              `<b>${this.$t("ALERT.height")}:</b> ` +
              img.height +
              ` ${this.$t("ALERT.pixel")} ` +
              "<br>",
            showCloseButton: true,
            focusConfirm: false,
            confirmButtonText: "OK",
            confirmButtonColor: "#e33354",
          });
        };
      } else {
        if (this.routeName != "internal_documents" && download) {
          Swal.fire({
            title: `<strong>${this.$t("ALERT.file_info_title")}</strong>`,
            customClass: "swal-wide",
            icon: "info",
            width: "500px",
            html:
              `<b>${this.$t("ALERT.created_at")}:</b> ` +
              this.getFormattedDateTime(file.created_at) +
              "<br>" +
              `<b>${this.$t("ALERT.name")}:</b> ` +
              file.name +
              "<br>" +
              `<b>${this.$t("ALERT.mime_type")}:</b> ` +
              file.mime_type +
              "<br>" +
              `<b>${this.$t("ALERT.path")}:</b>
                    <a href="` +
              this.getFile(file) +
              `" target="_blank">` +
              this.getFile(file) +
              `</a>` +
              "<br>" +
              `<b>${this.$t("ALERT.size")}:</b> ` +
              this.bytesToSize(file.size) +
              "<br>",
            showCloseButton: true,
            focusConfirm: false,
            confirmButtonText: "OK",
            confirmButtonColor: "#e33354",
          });
        } else {
          Swal.fire({
            title: `<strong>${this.$t("ALERT.file_info_title")}</strong>`,
            customClass: "swal-wide",
            icon: "info",
            width: "500px",
            html:
              `<b>${this.$t("ALERT.created_at")}:</b> ` +
              this.getFormattedDateTime(file.created_at) +
              "<br>" +
              `<b>${this.$t("ALERT.name")}:</b> ` +
              file.name +
              "<br>" +
              `<b>${this.$t("ALERT.size")}:</b> ` +
              this.bytesToSize(file.size) +
              "<br>" +
              `<b>${this.$t("ALERT.path")}:</b>
                      <a href="` +
              this.getFile(file) +
              `" target="_blank">` +
              this.getFile(file) +
              `</a>` +
              "<br>",
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: true,
            confirmButtonText: this.$t("FORMS.download"),
            cancelButtonText: this.$t("FORMS.cancel"),
            confirmButtonColor: "#e33354",
          }).then((result) => {
            if (result.isConfirmed) {
              axios({
                url:
                  process.env.VUE_APP_BACKEND_URL+'api/' +
                  "attachment/file/download",
                method: "POST",
                responseType: "blob",
                data: {
                  path: file.path,
                },
              }).then((response) => {
                var fileURL = window.URL.createObjectURL(
                  new Blob([response.data])
                );
                var fileLink = document.createElement("a");

                fileLink.href = fileURL;
                fileLink.setAttribute("download", file.name);
                document.body.appendChild(fileLink);

                fileLink.click();
              });
            }
          });
        }
      }
    },

    getNumOfSubdirectoryItems(dir_index) {
      if (
        this.num_of_subdirectory_items.length > 0 &&
        typeof this.num_of_subdirectory_items[dir_index] !== "undefined"
      ) {
        return this.num_of_subdirectory_items[dir_index];
      } else {
        return -1;
      }
    },

    handleChangeOrder(index) {
      this.sortBy[index].orderByAsc = !this.sortBy[index].orderByAsc;
      this.sortFiles(index);
    },

    sortFiles(index = null) {
      if (index === null) {
        index = this.sortedByIndex;
      }

      if (typeof this.sortedByIndex !== "undefined") {
        let files = this.files;
        let orderByAsc = this.sortBy[index].orderByAsc;
        let o2 = -1;
        let o1 = 1;
        if (orderByAsc) {
          o2 = 1;
          o1 = -1;
        }
        files.sort((a, b) =>
          a[this.sortBy[index].property] > b[this.sortBy[index].property]
            ? o2
            : a[this.sortBy[index].property] < b[this.sortBy[index].property]
            ? o1
            : 0
        );
      }
    },

    getThumbNail(path) {
      return path.replace("public", "image/250x175");
    },

    onSearch() {
      if (this.searchText.length < 3) return null;

      ApiService.get(
        // "career/admin/" + SiteService.getActiveSiteId() + "/career"
        "attachment/file"
      ).then(({ data }) => {
        if (data.length > 0) {
          let result = data
            .filter(
              (item) =>
                item.file_name.indexOf(this.searchText) > -1 &&
                item.path.indexOf("public/" + SiteService.getActiveSiteId()) >
                  -1
            )
            .map((item) => {
              item.name = item.file_name;
              return item;
            });

          let resultDirectories = data
            .filter((item) => {
              let pathArray = item.path.split("/");
              let directoryName = pathArray[pathArray.length - 2];

              return (
                directoryName.indexOf(this.searchText) > -1 &&
                item.path.indexOf("public/" + SiteService.getActiveSiteId()) >
                  -1
              );
            })
            .map((item) => {
              let pathArray = item.path.split("/");
              let directoryName = pathArray[pathArray.length - 2];
              pathArray.pop();
              let dir = {};

              dir.name = directoryName;
              dir.path = pathArray.join("/");
              dir.icon = "/media/media_library/folder.svg";

              return dir;
            });

          let uniqueResultDirectories = [];

          resultDirectories.forEach((item) => {
            if (!uniqueResultDirectories.find((dir) => dir.path == item.path)) {
              uniqueResultDirectories.push(item);
            }
          });
          if (result.length > 0 || uniqueResultDirectories.length > 0) {
            this.directories = [...uniqueResultDirectories];
            this.num_of_subdirectory_items = [];
            this.selectedFiles = [];
            this.selectAll = false;

            this.files = [...result];
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: this.$t("FORMS.not_found"),
            });
          }
        }
      });
    },

    handleClearSearchText() {
      this.searchText = null;
      this.searchBoxClosed = false;
      this.listDirectory();
    },

    handleChangeViewType() {
      if (this.viewType == "grid") {
        this.viewType = "list";
      } else {
        this.viewType = "grid";
      }
    },

    getFormattedDateTime(value) {
      return moment(String(value)).format("YYYY.MM.DD hh:mm");
    },

    handleIndexChange(index) {
      this.selectedIndex = index;
      this.setPathParams(this.actual_directory, index);
    },

    setPathParams(directory, fileIndex) {
      if (this.opener) return null;

      let filePath = null;
      if (fileIndex !== null) {
        filePath = this.files[fileIndex].path;
      }
      this.$router
        .replace({
          name: this.routeName,
          query: { directory: directory, filePath: filePath },
        })
        .catch(() => {});
    },

    openFromURL(filePath) {
      if (!filePath) return null;
      let file = this.files.find((item) => item.path == filePath);

      if (file) {
        this.handleInfoBox(file, true);
      }
    },

    handleShare(directory, filePath) {
      let snackbarInfo = this.snackbarInfo;

      let path = this.$router.resolve({
        name: this.routeName,
        query: { directory: directory, filePath: filePath },
      });

      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard
          .writeText(location.host + "/" + path.href)
          .then(function () {
            snackbarInfo.active = true;
          })
          .catch(function (error) {
            console.error("Hiba történt a szöveg másolása közben:", error);
          });
      } else {
        Swal.fire({
          icon: "success",
          title: "URL",
          text: location.host + "/" + path.href,
        });
      }
    },

    permissionCan(action) {
      // if (!this.permissions) return false;
      return this.permissions.find((x) => x.name == action);
    },

    setPermissions() {
      if (localStorage.permissions) {
        this.permissions = JSON.parse(localStorage.permissions).filter(
          (item) => {
            return (
              item.name.indexOf("menu") > -1 || item.name.indexOf("modul") > -1
            );
          }
        );
      }
    },
  },

  // mounted() {
  //   },

  // created: function () {
  mounted() {
    this.setPermissions();
    this.routeName = this.$route.name;
    if (this.routeName == "internal_documents") {
      if (!this.permissionCan("system.documentLibrary.menu")) {
        this.$router.push({ name: "403" });
      }
      this.root_directory = "internal_documents";
      this.actual_directory = "internal_documents";
    } else {
      if (!this.permissionCan("system.mediaLibrary.menu")) {
        this.$router.push({ name: "403" });
      }
      this.actual_directory = this.getSiteSpecificRootFolder();
    }

    if (this.$route.query.directory) {
      this.actual_directory = this.$route.query.directory ?? "";

      let pathArray = this.actual_directory.split("/");

      this.root_directory = pathArray[0];

      if (pathArray[0] == "public") {
        // this.root_directory += "/" + pathArray[1];
        SiteService.setActiveSiteId(pathArray[1]);
      }
    }

    this.maxWidth = this.getUrlParam("maxWidth");
    this.funcNum = this.getUrlParam("CKEditorFuncNum");
    this.viewType = process.env.VUE_APP_MEDIA_LIBRARY_VIEW ?? "grid";
    this.listDirectory(this.actual_directory);
  },
};
</script>

<style scoped>
.v-input.expanding-search {
  transition: max-width 0.5s;
}
.v-input.expanding-search.closed {
  max-width: 70px;
}
</style>
