<template>
  <v-dialog v-model="dialogSelectedDelete" max-width="550px">
    <v-card>
      <v-card-title v-if="dialogSelectedDeleteTitle" class="headline">{{
        dialogSelectedDeleteTitle
      }}</v-card-title>
      <v-card-title v-else class="headline"
        >{{ $t("FORMS.are_you") }}?</v-card-title
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="$emit('closeSelectedDelete')">{{
          $t("FORMS.cancel")
        }}</v-btn>
        <v-btn color="primary" @click="$emit('confirmDeleteSelectedGallery')"
          >OK</v-btn
        >

        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeleteSelectedModalDialog",
  props: ["dialogSelectedDelete", "dialogSelectedDeleteTitle"],
};
</script>
